<template>
  <v-container>
    <v-row class="my-0">
      <v-col cols="12" md="3">
        <v-select label="类型" v-model="query.platform_id" @change="requestList(true)" :items="platformList"
                  item-value="id" item-text="name" clearable dense outlined hide-details></v-select>
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field label="账号" v-model="query.account" @click:append="requestList(true)" append-icon="mdi-magnify" clearable dense outlined hide-details></v-text-field>
      </v-col>
      <v-col cols="12" md="3">
        <v-autocomplete label="手机号" v-model="query.phone_id" :items="phoneList" @change="requestList(true)"
                        item-value="id" item-text="number" clearable dense outlined hide-details></v-autocomplete>
      </v-col>
      <v-col cols="12" md="3">
        <v-select label="状态" v-model="query.status" @change="requestList(true)" :items="$store.state.accountStatusList"
                  clearable dense outlined hide-details></v-select>
      </v-col>
    </v-row>
    <v-data-table :headers="headers" :items="list" :items-per-page="query.size" :loading="requesting" hide-default-footer>
      <template v-slot:top>
        <v-toolbar flat class="">
          <v-btn color="primary" @click="showAddDialog">
            <v-icon>mdi-plus</v-icon>
            添加账号
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="requestList" :loading="requesting">
            <v-icon>mdi-refresh</v-icon>
            刷新
          </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:item.platform="{item}">
        {{ platformList.find(e => e.id === item.platform_id).name }}
      </template>
      <template v-slot:item.account="{item}">
        {{ item.account ? item.account : (item.phone ? item.phone.number : '') }}
      </template>
      <template v-slot:item.phone="{item}">
        {{ item.phone ? item.phone.number : '' }}
      </template>
      <template v-slot:item.wx="{item}">
        {{ item.wx_id ? wxList.find(e => e.id === item.wx_id).nickname : '' }}
      </template>
      <template v-slot:item.status="{item}">
        <v-chip color="primary" small outlined>状态{{ item.status }}</v-chip>
      </template>
      <template v-slot:item.actions="{item}">
        <v-btn color="primary" @click="showEditDialog(item)" class="ms-2" small outlined>
          <v-icon small>mdi-square-edit-outline</v-icon>
          编辑
        </v-btn>
        <v-btn color="error" @click="showDelDialog(item)" class="ms-2" small outlined>
          <v-icon small>mdi-delete-outline</v-icon>
          删除
        </v-btn>
      </template>
      <template v-slot:footer>
        <v-row class="ma-4" align="center">
          <span class="mr-2">总计:{{ total }}条</span>
          <v-pagination v-model="query.page" @input="requestList" class="my-4" :length="Math.ceil(total/query.size)" :total-visible="10"></v-pagination>
        </v-row>
      </template>
    </v-data-table>
    <v-dialog v-model="addDialog" persistent scrollable max-width="800">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar dark color="primary">
            <v-toolbar-title>添加账号</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="addDialog=false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
        </v-card-title>
        <v-card-text class="py-6">
          <v-row>
            <v-col cols="6">
              <v-select label="所属平台" v-model="item.platform_id" :items="platformList" item-value="id" item-text="name" outlined hide-details></v-select>
            </v-col>
            <v-col cols="6">
              <v-text-field type="date" label="注册时间" v-model="item.registered_at" outlined hide-details></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field label="账号" v-model="item.account" outlined hide-details></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field label="密码" v-model="item.password" outlined hide-details></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-autocomplete label="手机号" v-model="item.phone_id" :items="phoneList" :loading="querying" item-value="id" item-text="number"
                              outlined hide-details></v-autocomplete>
            </v-col>
            <v-col cols="6">
              <v-autocomplete label="微信号" v-model="item.wx_id" :items="wxList" :loading="querying" item-value="id" item-text="nickname"
                              outlined hide-details></v-autocomplete>
            </v-col>
            <v-col cols="6">
              <v-autocomplete label="QQ号" v-model="item.qq_id" :items="qqList" :loading="querying" item-value="id" item-text="nickname"
                              outlined hide-details></v-autocomplete>
            </v-col>
            <v-col cols="6">
              <v-text-field label="昵称" v-model="item.nickname" outlined hide-details></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field label="备注" v-model="item.remarks" outlined hide-details></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" large text @click="addDialog=false">取消</v-btn>
          <v-btn color="primary" :loading="operating" large @click="addItem">确认添加</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="editDialog" persistent scrollable max-width="800">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar dark color="primary">
            <v-toolbar-title>编辑账号</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="editDialog=false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
        </v-card-title>
        <v-card-text class="py-6">
          <v-row>
            <v-col cols="6">
              <v-select label="所属平台" v-model="item.platform_id" :items="platformList" item-value="id" item-text="name" outlined hide-details></v-select>
            </v-col>
            <v-col cols="6">
              <v-text-field type="date" label="注册时间" v-model="item.registered_at" outlined hide-details></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field label="账号" v-model="item.account" outlined hide-details></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field label="密码" v-model="item.password" outlined hide-details></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-autocomplete label="手机号" v-model="item.phone_id" :items="phoneList" :loading="querying" item-value="id" item-text="number"
                              outlined hide-details></v-autocomplete>
            </v-col>
            <v-col cols="6">
              <v-autocomplete label="微信号" v-model="item.wx_id" :items="wxList" :loading="querying" item-value="id" item-text="nickname"
                              outlined hide-details></v-autocomplete>
            </v-col>
            <v-col cols="6">
              <v-autocomplete label="QQ号" v-model="item.qq_id" :items="qqList" :loading="querying" item-value="id" item-text="nickname"
                              outlined hide-details></v-autocomplete>
            </v-col>
            <v-col cols="6">
              <v-text-field label="昵称" v-model="item.nickname" outlined hide-details></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field label="备注" v-model="item.remarks" outlined hide-details></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" large text @click="editDialog=false">取消</v-btn>
          <v-btn color="primary" :loading="operating" large @click="editItem">确认保存</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="delDialog" persistent scrollable max-width="600">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar dark color="primary">
            <v-toolbar-title>删除账号</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="delDialog=false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
        </v-card-title>
        <v-card-text class="pa-6">
          <h3>确定要删除该账号【{{ item.nickname }}({{ item.account }})】吗？</h3>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" large text @click="delDialog=false">取消</v-btn>
          <v-btn color="primary" large :loading="operating" @click="delItem">确定删除</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      requesting: false,
      operating: false,
      querying: false,
      headers: [
        {text: '平台', value: 'platform', align: 'center', sortable: false},
        {text: '账号', value: 'account', align: 'center', sortable: false},
        {text: '密码', value: 'password', align: 'center', sortable: false},
        {text: '手机号', value: 'phone', align: 'center', sortable: false},
        {text: '微信', value: 'wx', align: 'center', sortable: false},
        {text: '注册日期', value: 'registered_at', align: 'center', sortable: false},
        {text: '备注', value: 'remarks', align: 'center', sortable: false},
        {text: '操作', value: 'actions', align: 'center', sortable: false},
      ],
      query: {page: 1, size: 10, type: '', status: ''},
      list: [],
      total: 0,
      addDialog: false,
      editDialog: false,
      delDialog: false,
      loginDialog: false,
      item: {},
      platformList: [],
      phoneList: [],
      wxList: [],
      qqList: [],
      status: 0,
      captcha: '',
    }
  },
  watch: {},
  mounted() {
    this.requestPlatformList()
    this.requestPhoneList()
    this.requestWxList()
    this.requestQQList()
    this.requestList()
  },
  methods: {
    showAddDialog() {
      this.item = {}
      this.addDialog = true
    },
    showEditDialog(item) {
      Object.assign(this.item, item)
      this.editDialog = true
      this.requestPhoneList()
    },
    showDelDialog(item) {
      Object.assign(this.item, item)
      this.delDialog = true
    },
    requestPlatformList() {
      if (this.platformList.length > 0) return
      this.querying = true
      this.axios.get(`platform`, {params: {size: 9999}})
          .then(response => {
            this.platformList = response.data.data
          })
          .finally(() => this.querying = false)
    },
    requestPhoneList() {
      if (this.phoneList.length > 0) return
      this.querying = true
      this.axios.get(`phone`, {params: {size: 9999}})
          .then(response => {
            this.phoneList = response.data.data
          })
          .finally(() => this.querying = false)
    },
    requestWxList() {
      if (this.wxList.length > 0) return
      this.querying = true
      this.axios.get(`account/index?platform_id=1`, {params: {size: 9999}})
          .then(response => {
            this.wxList = response.data.data
          })
          .finally(() => this.querying = false)
    },
    requestQQList() {
      if (this.qqList.length > 0) return
      this.querying = true
      this.axios.get(`account/index?platform_id=2`, {params: {size: 9999}})
          .then(response => {
            this.qqList = response.data.data
          })
          .finally(() => this.querying = false)
    },
    addItem() {
      this.operating = true
      this.axios.post(`account/add`, this.item)
          .then(response => {
            this.$store.commit('toast', response.data.message)
            this.addDialog = false
            this.requestList()
          })
          .finally(() => this.operating = false)
    },
    editItem() {
      this.operating = true
      this.axios.post(`account/edit`, this.item)
          .then(response => {
            this.item = {}
            this.$store.commit('toast', response.data.message)
            this.editDialog = false
            this.requestList()
          })
          .finally(() => this.operating = false)
    },
    delItem() {
      this.operating = true
      this.axios.post(`account/del`, {id: this.item.id})
          .then(response => {
            this.$store.commit('toast', response.data.message)
            this.delDialog = false
            this.requestList()
          })
          .finally(() => this.operating = false)
    },
    refreshItem(id) {
      this.axios.get(`account/${id}/refresh`)
          .then(response => {
            this.$store.commit('toast', response.data.message)
            this.requestList()
          })
    },
    requestList(changed) {
      if (changed === true) this.query.page = 1
      this.requesting = true
      this.axios.get(`account`, {params: this.query})
          .then(response => {
            this.list = response.data.data
            this.total = response.data.total
          })
          .finally(() => this.requesting = false)
    },
    copy(text) {
      // 创建输入框元素
      let oInput = document.createElement('input')
      // 将想要复制的值
      oInput.value = text
      // 页面底部追加输入框
      document.body.appendChild(oInput)
      // 选中输入框
      oInput.select();
      // 执行浏览器复制命令
      document.execCommand('Copy')
      // 弹出复制成功信息
      this.$store.commit('toast', '复制成功')
      // 复制后移除输入框
      oInput.remove()
    },
  }
}
</script>

<style scoped>

</style>